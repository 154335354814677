import * as ui from "semantic-ui-react";

export function scoreColor(score: number): ui.SemanticCOLORS {
    let result: ui.SemanticCOLORS = 'green';

    if (score < 90) result = 'olive';
    if (score < 80) result = 'yellow';
    if (score < 70) result = 'orange';
    if (score < 60) result = 'red';
    return result
}
