import {InfoCardProps} from "./componenets/InfoCard";

export const companyInfo = {
    name: 'Oracle NetSuite ERP',
    logoSrc: '/oracle-netsuite.png',
    url: 'https://www.netsuite.com/',
    displayUrl: 'netsuite.com',
    industry: 'Financial Services',
};

export type CategoryKey = 'competitor' | 'momentum' | 'product' | 'todo' | 'budget' | 'warmth' | 'qualified';

export const categoryKeyList: CategoryKey[] = [
    'competitor',
    'todo',
    'budget',
    'warmth',
    'qualified',
    'product',
    'momentum',
];

export const scores = {
    deal: 80,
    competitor: 0,
    todo: 50,
    budget: 75,
    warmth: 100,
    qualified: 100,
    product: 75,
    momentum: 40,
};

export const categoryDisplayName = {
    competitor: 'Competitor',
    momentum: 'Momentum',
    product: 'Product',
    todo: 'Follow Up',
    budget: 'Budget',
    warmth: 'Contact Warmth',
    qualified: 'Buy Qualification',
};

export const events: InfoCardProps[] = [
    {
        category: 'competitor',
        indicator: 'positive',
        title: 'Salesforce',
        date: "12/1/19",
        time: "11:47 AM",
        quote: '"Being totally honest, we\'re considering salesforce as well"',
        quoteSource: "Chorus.ai Transcript",
        contactName: "Susan Stone"
    },
    {
        category: 'todo',
        indicator: 'negative',
        title: 'White Paper (Awaiting)',
        date: "12/15/19",
        time: "11:47 AM",
        quote: '"We have a great whitepaper on that. I\'ll send it over right after the call"',
        quoteSource: "Chorus.ai Transcript",
        contactName: "Susan Stone"
    },
    {
        category: 'todo',
        indicator: 'positive',
        title: 'Price Quote (Complete)',
        date: "12/17/19",
        time: "11:47 AM",
        quote: '"I am not quite sure we can bring it so low but I\'ll reach out to my boss and let you know."',
        quoteSource: "Chorus.ai Transcript",
        contactName: "Susan Stone"
    },
    {
        category: 'budget',
        indicator: 'positive',
        title: 'Buying Indicator',
        date: '12/19/19',
        time: "11:47 AM",
        quote: '"12/29/19"',
        quoteSource: "Chorus.ai Transcript",
        contactName: "Susan Stone"
    },
    {
        category: 'budget',
        indicator: 'positive',
        title: 'Buying Indicator',
        date: '12/29/19',
        time: "11:47 AM",
        quote: '"got funding last month"',
        quoteSource: "Chorus.ai Transcript",
        contactName: "Susan Stone"
    },
    {
        category: 'budget',
        indicator: 'positive',
        title: 'Buying Indicator',
        date: '12/28/19',
        time: "11:47 AM",
        quote: '"added four clients today!"',
        quoteSource: "Chorus.ai Transcript",
        contactName: "Susan Stone"
    },
    {
        category: 'budget',
        indicator: 'positive',
        title: 'Concern',
        date: '12/23/2019',
        time: "11:47 AM",
        quote: '"..FeatureWidg looks expensive.."',
        quoteSource: "Chorus.ai Transcript",
        contactName: "Susan Stone"
    },
    {
        category: 'warmth',
        indicator: 'positive',
        title: 'Champion',
        date: "12/23/19",
        time: "11:47 AM",
        quote: '"Had a great experience with you guys at my last company"',
        quoteSource: "Chorus.ai Transcript",
        contactName: "Susan Stone"
    },
    {
        category: 'warmth',
        indicator: 'positive',
        title: 'Champion',
        date: "12/21/19",
        time: "11:47 AM",
        quote: '"jeff is on board"',
        quoteSource: "Chorus.ai Transcript",
        contactName: "Susan Stone"
    },
    {
        category: 'qualifications',
        indicator: 'positive',
        title: 'Are they growing?',
        date: "12/29/19",
        time: "11:47 AM",
        quote: '"12/29/19"',
        quoteSource: 'PitchBook',
        contactName: "Susan Stone"
    },

];

export const historicalDealScores = [
    {
        "name": "12/1/19",
        "Deal Score": 30,
    },
    {
        "name": "12/3/19",
        "Deal Score": 25,
    },
    {
        "name": "12/5/19",
        "Deal Score": 40,
    },
    {
        "name": "12/7/19",
        "Deal Score": 35,
    },
    {
        "name": "12/9/19",
        "Deal Score": 45,
    },
    {
        "name": "12/11/19",
        "Deal Score": 60,
    },
    {
        "name": "12/13/19",
        "Deal Score": 60,
    },
    {
        "name": "12/15/19",
        "Deal Score": 30,
    },
    {
        "name": "12/17/19",
        "Deal Score": 30,
    },
    {
        "name": "12/19/19",
        "Deal Score": 75,
    },
    {
        "name": "12/21/19",
        "Deal Score": 80,
    },
    {
        "name": "12/23/19",
        "Deal Score": 83,
    },
    {
        "name": "12/25/19",
        "Deal Score": 83,
    },
    {
        "name": "12/27/19",
        "Deal Score": 92,
    },
    {
        "name": "12/29/19",
        "Deal Score": 100,
    },
];