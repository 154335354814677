import React from 'react';
import * as ui from 'semantic-ui-react'
import Deal, {dealPages} from "./componenets/Deal";


class App extends React.Component<any, any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            page: dealPages.scan
        }
    }

    render() {
        return (

            <ui.Segment>
                <ui.Menu inverted>
                    <ui.Menu.Item>{this.state.page}</ui.Menu.Item>
                    <ui.Menu.Menu position={'right'}>
                        <ui.MenuItem onClick={() => this.setState({page: dealPages.scan})}>
                            <ui.Button active={dealPages.scan === this.state.page}>{dealPages.scan}</ui.Button>
                        </ui.MenuItem>
                        <ui.MenuItem onClick={() => this.setState({page: dealPages.track})}>
                            <ui.Button active={dealPages.track === this.state.page}>{dealPages.track}</ui.Button>
                        </ui.MenuItem>
                        <ui.MenuItem onClick={() => this.setState({page: dealPages.log})}>
                            <ui.Button active={dealPages.log === this.state.page}>{dealPages.log}</ui.Button>
                        </ui.MenuItem>
                    </ui.Menu.Menu>
                </ui.Menu>
                <Deal page={this.state.page}/>
        </ui.Segment>
        )
    }
}

export default App;