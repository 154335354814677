import React from "react";
import * as ui from 'semantic-ui-react';
import InfoCard, {InfoCardProps} from "./InfoCard";
import {scoreColor} from "../util";
import {categoryDisplayName, categoryKeyList, CategoryKey, events, scores} from "../data";


export default class DealScan extends React.Component {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <ui.Segment padded>
                <ui.Header>Breakdown</ui.Header>
                <EventsByCategory/>
            </ui.Segment>
        )
    }
}

function EventsByCategory(props: {}) {
    const grouped = categoryKeyList.map(cat => ({key: cat, items: events.filter(x => x.category === cat)}));
    return (
        <ui.SegmentGroup>
            {
                grouped.map(({key, items}) => <Category catKey={key} findings={items as any}/>)
            }
        </ui.SegmentGroup>
    );
}

interface ICategoryProps {
    catKey: CategoryKey
    active?: boolean
    findings: InfoCardProps[]
}

class Category extends React.Component<ICategoryProps, { active: boolean }> {
    constructor(props: ICategoryProps) {
        super(props);

        this.state = {
            active: !!props.active
        }
    }

    toggleActive() {
        this.setState({active: !this.state.active});
    }

    get score() {
        return scores[this.props.catKey];
    }

    get scoreColor() {
        return scoreColor(this.score);
    }

    get displayName() {
        return categoryDisplayName[this.props.catKey]
    }


    render() {
        return (
            <ui.Accordion>
                <ui.Accordion.Title active={this.state.active} onClick={() => this.toggleActive()}>
                    <ui.Segment>
                        <ui.Grid>
                            <ui.GridColumn width={2}>
                                <ui.Statistic horizontal value={this.score + '%'} color={this.scoreColor}/>
                            </ui.GridColumn>
                            <ui.GridColumn width={3}>
                                <ui.Header>{this.displayName}</ui.Header>
                            </ui.GridColumn>
                            <ui.GridColumn verticalAlign={'middle'}>
                                {this.props.findings.length} {this.props.findings.length === 1 ? 'Finding' : 'Findings'}
                            </ui.GridColumn>
                            <ui.GridColumn>
                                <ui.Icon name={this.state.active ? 'chevron up' : 'chevron down'}/>
                            </ui.GridColumn>
                        </ui.Grid>
                    </ui.Segment>
                </ui.Accordion.Title>
                <ui.Accordion.Content active={this.state.active}>
                    <Findings findings={this.props.findings}/>
                </ui.Accordion.Content>
            </ui.Accordion>
        );
    }
}

function Findings(props: { findings: InfoCardProps[] }) {
    if (props.findings.length === 0) {
        return <ui.Message content={'Nothing found for this factor'}/>
    }

    return (
        <ui.CardGroup>
            {
                props.findings.map(item => <InfoCard item={item}/>)
            }
        </ui.CardGroup>
    )
}