import * as ui from "semantic-ui-react";
import React from "react";


export interface InfoCardProps {
    indicator: 'positive' | 'negative'
    category: string

    title: string

    quote: string
    quoteSource: string

    contactName: string

    time: string
    date: string
}

export default function InfoCard(props: { item: InfoCardProps }) {
    const cardColor = props.item.indicator === 'negative' ? 'red' : 'green';
    return (
        <ui.Card color={cardColor}>
            <ui.CardContent>
                <ui.CardHeader>{props.item.title}</ui.CardHeader>
                <ui.CardDescription>{props.item.quote}</ui.CardDescription>
            </ui.CardContent>
            <ui.CardContent extra>
                <ui.CardMeta>Contact: {props.item.contactName}</ui.CardMeta>
                <ui.CardMeta>Source: {props.item.quoteSource}</ui.CardMeta>
            </ui.CardContent>
            <ui.CardContent extra>
                <ui.CardMeta>{props.item.time}</ui.CardMeta>
                <ui.CardMeta>{props.item.date}</ui.CardMeta>
            </ui.CardContent>
        </ui.Card>
    )
}