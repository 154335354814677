import React from "react";
import {companyInfo, scores} from "../data";
import CompanyHeader from "./CompanyHeader";
import DealScan from "./DealScan";
import DealTrack from "./DealTrack";
import DealLog from "./DealLog";

export const dealPages = {
    scan: 'Stylo DealScan',
    track: 'Stylo DealTrack',
    log: 'Stylo DealLog',
};


export default class Deal extends React.Component<{ page: string }, any, any> {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return (
            <div style={{"margin": '10px'}}>
                <CompanyHeader
                    score={scores.deal}
                    logoSrc={companyInfo.logoSrc}
                    name={companyInfo.name}
                    url={companyInfo.url}
                    displayUrl={companyInfo.displayUrl}
                    industry={companyInfo.industry}
                />
                {(() => {
                    switch (this.props.page) {
                        case dealPages.scan:
                            return <DealScan/>;
                        case dealPages.track:
                            return <DealTrack/>;
                        case dealPages.log:
                            return <DealLog/>;
                    }
                })()}
            </div>
        )
    }
}

