import React from "react";
import * as ui from 'semantic-ui-react';
import {events, historicalDealScores} from "../data";
import InfoCard from "./InfoCard";



export default class DealLog extends React.Component<any, any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            current: DateRange[0]
        };
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return (
            <div>
                <ui.Message content={'Deal History'}/>
            <ui.Segment>
                <ui.Grid>
                 <ui.Grid.Column width={1}>
                     <ui.ButtonGroup vertical>
                         {
                             DateRange.map(x => {
                                 return (
                                     <ui.Button onClick={() => this.setState({current: x})}>
                                         {`${x}`}
                                     </ui.Button>
                                 )
                             })
                         }
                      </ui.ButtonGroup>
                    </ui.Grid.Column>
                    <ui.Grid.Column stretched width={15}>
                            <DateCardGroupLeft current={this.state.current}/>
                    </ui.Grid.Column>
                </ui.Grid>

            </ui.Segment>
            </div>
        )
    }
}



const DateCardGroupLeft = (props: { current: string }) => {
    if (props.current === '') {
        return <ui.Message>Select a date on the left for details</ui.Message>
    }


    const filtered = events.filter(x => x.date === props.current);
    if (filtered.length === 0) {
        return <ui.Message>No events on {props.current}</ui.Message>
    }

    return (
        <ui.Segment>
            <ui.CardGroup>
                {
                    filtered.map((y: any) => <InfoCard item={y}/>)
                }
            </ui.CardGroup>
        </ui.Segment>
    );
};

const DateRange = historicalDealScores.map(historicalDealScores => historicalDealScores.name).slice().reverse();