import * as ui from "semantic-ui-react";
import {scoreColor} from "../util";
import React from "react";
import { Divider, Rail } from 'semantic-ui-react'

export function statusText(score: number) {
    if (score === 100) {
        return ' (won)';
    }
    if (score === 0) {
        return ' (lost)';
    }
    return '';
}

interface CompanyHeaderProps {
    score: number
    logoSrc: string
    name: string
    url: string
    displayUrl: string
    industry: string
}

export default function CompanyHeader(props: CompanyHeaderProps) {
    return (
            <ui.Segment placeholder textAlign={"center"}>
            <ui.Grid textAlign='center' verticalAlign='middle'>
                <Rail attached internal position='left'>
                    <ui.Segment>
                        <a href={props.url}
                                rel="noopener noreferrer"
                                target="_blank">
                        {props.name}</a>
                        | {props.industry}
                    </ui.Segment>
                </Rail>
                <ui.Grid.Column floated='left' width={2}>
                   <ui.Image src={props.logoSrc} size={'small'}/>
                </ui.Grid.Column>
                <ui.Grid.Column verticalAlign='middle' floated='left' width={5}>
                    <ui.Statistic label={'Deal Score' + statusText(props.score)}
                                  value={props.score + '%'}
                                  color={scoreColor(props.score)}
                                  size='huge'/>
                </ui.Grid.Column>
            </ui.Grid>
         <Divider vertical/>
         </ui.Segment>
    );
}