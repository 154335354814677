import React from "react";
import * as ui from 'semantic-ui-react';
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import InfoCard from "./InfoCard";
import {categoryDisplayName, categoryKeyList, events, historicalDealScores, scores} from "../data";

export default class DealTrack extends React.Component<any, any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            current: ''
        };
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return (
            <div style={{"margin": '10px'}}>
                <ui.Container fluid>
                    <ui.Grid columns={3}>
                        <ui.GridColumn width={2}>
                                    <ui.ButtonGroup vertical>
                                        {
                                            categoryKeyList.map(category => {
                                                const score = scores[category];
                                                const displayName = categoryDisplayName[category];
                                                return (
                                                    <ui.Button onClick={() => this.setState({current: category})}>
                                                        {`${displayName} ${score}%`}
                                                    </ui.Button>
                                                )
                                            })
                                        }
                                    </ui.ButtonGroup>
                        </ui.GridColumn>
                        <ui.GridColumn>
                            <LineChart width={730} height={500} data={historicalDealScores}
                                       margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Line type="monotone" dataKey="Deal Score" stroke="#8884d8"/>
                            </LineChart>
                        </ui.GridColumn>
                        <ui.GridColumn>
                                <CardGroupLeft current={this.state.current}/>
                        </ui.GridColumn>
                    </ui.Grid>
                </ui.Container>

            <ui.Message content={'Deal History'}/>
            <ui.Segment>
            <ui.Grid>
                <ui.Grid.Column width={1}>
                    <ui.ButtonGroup vertical>
                        {
                            DateRange.map(x => {
                                return (
                                    <ui.Button onClick={() => this.setState({current: x})}>
                                        {`${x}`}
                                    </ui.Button>
                                )
                            })
                        }
                    </ui.ButtonGroup>
                </ui.Grid.Column>
                <ui.Grid.Column stretched width={15}>
                    <DateCardGroupLeft current={this.state.current}/>
                </ui.Grid.Column>
            </ui.Grid>

            </ui.Segment>
        </div>
        )
    };
};

const CardGroupLeft = (props: { current: string }) => {
        if (props.current === '') {
            return <ui.Message>Select an option to the left for more details</ui.Message>
        }


        const filtered = events.filter(x => x.category === props.current);
        if (filtered.length === 0) {
            return <ui.Message>No cards found for {props.current}</ui.Message>
        }

        return (
            <ui.Segment>
                <ui.CardGroup>
                    {
                        filtered.map((y: any) => <InfoCard item={y}/>)
                    }
                </ui.CardGroup>
            </ui.Segment>
        );
    };

const DateCardGroupLeft = (props: { current: string }) => {
    if (props.current === '') {
        return <ui.Message>Select a date on the left for details</ui.Message>
    }


    const filtered = events.filter(x => x.date === props.current);
    if (filtered.length === 0) {
        return <ui.Message>No events on {props.current}</ui.Message>
    }

    return (
        <ui.Segment>
            <ui.CardGroup>
                {
                    filtered.map((y: any) => <InfoCard item={y}/>)
                }
            </ui.CardGroup>
        </ui.Segment>
    );
};

const DateRange = historicalDealScores.map(historicalDealScores => historicalDealScores.name).slice().reverse();

/*
const NewCard  = (props: {current: string }) => {
    // payload[0] doesn't exist when tooltip isn't visible
    if (props === 'active') {
        return <ui.Message>Select an option above to get more details</ui.Message>
    }
    if (props != null) {
        // mutating props directly is against react's conventions
        // so we create a new payload with the name and value fields set to what we want
       return (
            <ui.CardGroup>
                {
                    props.label.map((y: any) => <InfoCard item={y}/>)
                }
            </ui.CardGroup>
    );
    return null
    }
};
*/
